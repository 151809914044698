import './App.css';
import Network from './network';
import SettingsWindow from './settingsWindow';
import { CButton, CCard, CCardBody, CContainer, CForm, CFormInput, 
 CImage, CSpinner, CModal, CModalHeader, CModalBody, CModalTitle, CModalFooter, CFormTextarea, 
 CCardTitle} from '@coreui/react';
import { useRef, useState, useEffect, memo } from 'react';
import logo from './assets/logo.png';
import axios from 'axios';
import icosaPassword from './icosaPassword.json'
import DownloadCSVButton from './downloadButton.js';

function App() {
  const [nodes, setNodes] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [finalNodes, setFinalNodes] = useState([]);
  const [selectedOption, setSelectedOption] = useState('gpt-4o-mini');
  const [isFinal, setIsFinal] = useState(true);
  const [answer, setAnswer] = useState(['','']);
  const [threshParam, setThreshParam] = useState(2.5);
  const [caption, setCaption] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [visible, setVisible] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const graphRef = useRef(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = (screen) => {
    setSelectedScreen(screen);
  };

  const [selectedScreen, setSelectedScreen] = useState('');



  //choose the screen size 
  const handleResize = () => {
    if ((window.innerWidth < 720) || (window.innerHeight < 560)) {
      setIsSmall(true)
    } else {
      setIsSmall(false)
    }
  }

  const removeNode = event => {
    setNodes((nodes) => {
      let newNodes = [...nodes];
      newNodes.pop();
      return newNodes
    });
  };

  const handleSubmit = async (e) => {
    setVisible(false)
    setIsLoading(true);
    setFinalNodes([]);
    axios.get(
        'https://cr-api.icosacomputing.com/cr/demo', 
      {params: {prompt: prompt, threshParam: threshParam, apiKey: apiKey, weight: 1, model: selectedOption},
       auth: {
        username: 'icosa',
        password: "tQ_g63ZrGROTlYhCpUf-EgpAP4z79C0ZmtAPbiEM5CM",
      }}
    )
      .then(async (response) => {
        console.log(response)
        setNodes(Array.from(response.data.initialReasons.slice(0, 200), (reasonTuple) => {
          let reason = reasonTuple[0]
          let count = reasonTuple[1]
          let firstWord = reason.split(' ')[0];
          return {id: reason, label: firstWord, caption: reason, data: {'weight': count}}}));
        setFinalNodes(Array.from(response.data.finalReasons, (reasonTuple) => {
          let reason = reasonTuple[0]
          let count = reasonTuple[1]
          let reasonStr = reasonTuple[0]; // remove braces
          let firstWord = reasonStr.split(' ')[0];
          return {id: reason + 'final', label: firstWord, caption: reasonStr, reason: reason,  data: {'weight': count}}}));
        let fullAns = response.data.answer
        let directAns = response.data.zeroShotAnswer
        setAnswer([fullAns, directAns]);
        setIsFinal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
  }

    const handleSubmitCreds = async (e) => {
      setVisible(false);
      setIsLoading(true);
      setFinalNodes([]);

      let creds = {};

      
      // Fetch the credentials from creds.json
      try {
          const credsResponse = await fetch('/creds.json');
          const credsText = await credsResponse.text();
          creds = JSON.parse(credsText);
      } catch (error) {
          console.error("Error fetching credentials:", error);
          setIsLoading(false);
          return;
      }

      // Check if username and password match any credentials in creds.json
      const isValidCred = Object.keys(creds).some((key) => {
          return key === username && creds[key] === password;
      });

      if (isValidCred) {
          axios.get('https://cr-api.icosacomputing.com/cr/demo_trial', {
              params: { prompt: prompt, threshParam: threshParam, weight: 1, model: selectedOption},
              auth: {
                  username: 'icosa-trial',
                  password: icosaPassword.password,
              }
          })
          .then(async (response) => {
              console.log(response);
              setNodes(Array.from(response.data.initialReasons.slice(0, 200), (reasonTuple) => {
                  let reason = reasonTuple[0];
                  let count = reasonTuple[1];
                  let firstWord = reason.split(' ')[0];
                  return { id: reason, label: firstWord, caption: reason, data: { 'weight': count } };
              }));
              setFinalNodes(Array.from(response.data.finalReasons, (reasonTuple) => {
                  let reason = reasonTuple[0];
                  let count = reasonTuple[1];
                  let reasonStr = reasonTuple[0]; // remove braces
                  let firstWord = reasonStr.split(' ')[0];
                  return { id: reason + 'final', label: firstWord, caption: reasonStr, reason: reason, data: { 'weight': count } };
              }));
              let fullAns = response.data.answer;
              let directAns = response.data.zeroShotAnswer;
              // Use the API key in requestAPI
              setAnswer([fullAns, directAns]);
              setIsFinal(false);
              setIsLoading(false);
          })
          .catch((error) => {
              console.log(error);
              setIsLoading(false);
          });
      } else {
          console.log("Invalid username or password");
          setIsLoading(false);
      }
  };


  const handleSolve = () => {
    if (finalNodes.length <= 1) {
      return
    }
    setIsFinal(true)
    setNodes(finalNodes)
    graphRef.current?.centerGraph()
  }

  if (isSmall) {
    return <CContainer fluid className="App">
      <div className='row' style={{marginTop: '1%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
            <CContainer sm style={{maxWidth: '20rem', marginRight: 'auto'}}>
                <CImage src={logo} fluid /> 
            </CContainer>
            {/*<CContainer fluid style={{maxWidth: 'fit-content', marginLeft: '0px', marginRight: 'auto',}}><h2>Icosa Computing</h2></CContainer>*/}
        </div>
        <div className='row' style={{marginTop: '2%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
          <p>Add reasoning capabilities to LLMs with Icosa Computing's Combinatorial Reasoner. Try it out here using GPT (API Key Needed)</p>
        </div>
        <CCard color='danger' fluid>
            <CCardTitle> Please use a bigger screen to access the demo </CCardTitle>
        </CCard>
    </CContainer>
  }

  return (
    <>
    <CContainer fluid className="App">
       <CModal
      visible={visible}
      onClose={() => setVisible(false)}
    >
<div style={{marginLeft: '5%', marginTop: '4%', marginRight: "4%"}}>
  <h2>Enter API Key Or Credentials:</h2>
  <CButton color="primary" style={{ margin: '0.4vw' }} onClick={() => handleButtonClick('screen1')}>API Key</CButton>
  <CButton color="primary" style={{ margin: '0.4vw' }} onClick={() => handleButtonClick('screen2')}>Login</CButton>

  {(() => {
    let content; // Declare content variable here
    switch (selectedScreen) {
      case 'screen1':
        content = (
          <div key="screen1">
            <CModalHeader>
              <CModalTitle>Enter API Key</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CFormInput
                value={apiKey}
                onChange={(e) => setAPIKey(e.target.value)}
                placeholder="API Key"
                label="Please enter your OpenAI API Key Here. We will make 212 calls to OpenAI with this key:"
              />
            </CModalBody>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => {
                  setVisible(false);
                  setAPIKey('');
                }}
              >
                Back
              </CButton>
              <CButton color="primary" onClick={handleSubmit}>Submit</CButton>
            </CModalFooter>
          </div>
        );
        break;
      case 'screen2':
        content = (
          <div key="screen2">
            <CModalHeader>
              <CModalTitle>Enter Credentials</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CFormInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                label="Please enter your username:"
              />
              <CFormInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                label="Please enter your password:"
              />
            </CModalBody>
               <div style={{ marginTop: '1rem' }}>
                <a href="https://docs.google.com/forms/d/1WNEoZMpXTdkelT_52OooSd8Rl7GQQt04M2J1gqA1rq4/edit" target="_blank" rel="noopener noreferrer">Register for access</a>
              </div>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => {
                  setVisible(false);
                  setUsername('');
                  setPassword('');
                }}
              >
                Back
              </CButton>
              <CButton color="primary" onClick={handleSubmitCreds}>Submit</CButton>
            </CModalFooter>
          </div>
        );
        break;
      default:
        content = <p>Please select an option.</p>; // Add a default case
    }
    return content; // Return the content from the IIFE
  })()}
</div>
    </CModal>
        <div className='row' style={{marginTop: '1%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
            <CContainer sm style={{maxWidth: '20rem', marginRight: 'auto'}}>
                <CImage src={logo} fluid /> 
            </CContainer>
            {/*<CContainer fluid style={{maxWidth: 'fit-content', marginLeft: '0px', marginRight: 'auto',}}><h2>Icosa Computing</h2></CContainer>*/}
        </div>
        <div className='row' style={{marginTop: '2%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
          <p>Add reasoning capabilities to LLMs with Icosa Computing's Combinatorial Reasoner. Try it out here using GPT (API Key Needed)</p>
        </div>
        <div className='row' style={{marginTop: '0%'}}>
          
    <div style={{marginLeft: '3%', width: '12%'}}>
      <select value={selectedOption} onChange={handleChange}>
        <option value="gpt-4o-mini">GPT 4o Mini</option>
        <option value="gpt-4o">GPT 4o</option>
      </select>
    </div>
          <div className='col' style={{marginLeft: '10.5%', maxWidth: '48%', marginRight: '0px',}}>
            <CForm onSubmit={(e) => {
              e.preventDefault();
              setVisible(true);
            }}>
                {/*<CFormSelect 
                label="Prompt:"
                options={[
                  { label: 'Write your prompt here...', disabled: true },
                  ...promptOptions
                ]} 
                value={prompt} 
                onChange={(e) => {setPrompt(e.target.value)}} 
                placeholder='Write your prompt here...'
                />*/}
                
            {<CFormTextarea 
              value={prompt} 
              onChange={(e) => {setPrompt(e.target.value)}} 
              placeholder='Write your prompt here...'
              label="Please write your reasoning intensive question here:"
              rows={3} // Set initial height of textarea
              style={{ overflowY: 'auto' }} // Enable vertical scrolling
            />}
                <div style={{marginLeft: 'auto', maxWidth: '5%', marginRight: 'auto',}}>
                <CButton type='submit'>Go!</CButton>
                </div>
            </CForm>
          </div>
          <div className='col' style={{ marginLeft: '6%', maxWidth: '18%', marginRight: '3%',}}>
              <SettingsWindow onThreshParamChange={(e) => {setThreshParam(e.target.value)}} threshParam={threshParam} 
              disabled={isLoading || !isFinal}/>
          </div>
        </div>
        <div className='row'>
        {
        isLoading ?  
        <div className='col' style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '15%', textAlign: 'center' }}>
          <CSpinner />
          <div>Brainstorming...</div> 
        </div>
           : 
           <>
        <div className='col' style={{position: 'fixed', marginLeft: '79%', maxWidth: '18%',}}>
            {(answer[0].length > 0) && isFinal ? <>
            <CCard >
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    RESPONSE WITHOUT CR: <br/>
                    { answer[1]}
                </div>
              </CCardBody>
            </CCard> 
        <div classname="downloadButton" style={{maxWidth: '101%', marginTop: '50%'}}>
          <DownloadCSVButton data={finalNodes}/>
        </div>
            </>
            :
            <></>
            }
        </div>
    
        <myDiv className='col' style={{position: 'fixed', marginLeft: '2%', maxWidth: '18%'}}>
            {(answer[0].length > 0) && isFinal ? <>
            <CCard style={{border: 'solid purple'}} >       
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    RESPONSE WITH CR: <br/>
                    {answer[0]}
                </div>
              </CCardBody>
            </CCard> 
            </>
            :
            <></>
            }
        </myDiv>
        
        <div className='col' style={{position: 'fixed', marginLeft: '2%', maxWidth: '18%', marginTop: '20%'}}>
            <CCard>
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    FULL REASON: {caption}
                </div>
              </CCardBody>
            </CCard>
            <p></p> 
        </div>
      
      <div>
      </div>
      <div className='col' style={{position:'fixed', marginLeft: '19%', maxWidth: 'fit-content', marginRight: 'auto', marginTop: '2%'}}>
         <Network nodes={nodes} fullyConnected={false} caption={caption} setCaption={setCaption} graphRef={graphRef}/>
      </div>
      </>
      }
      </div>
    </CContainer>
    {isFinal ? 
      /*<p style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: 'auto', marginTop: '30%'}}>{answer}</p>*/<></> :
      <div style={{position: 'fixed', marginLeft: '35%', maxWidth: 'fit-content', marginRight: '35%', marginBottom: '2rem', bottom: '0px', maxHeight: '10%'}}>
        <div style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: 'auto'}}>
         <CButton onClick={handleSolve} color='primary'>Select Reasons</CButton>
         </div>
         <p>Only 200 reasons are shown in order to minimize crowding</p>
        </div>
      }
      </>
  );
};

export default App;
